<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0 pt-6 pb-0">
      <div class="card-title">
        <div class="card-label">
          <h3>Reporte de proceso de matrícula</h3>
          <span class="d-block text-muted pt-2 font-size-sm"
            >Aqui se podra visualizar y descargar los reportes referentes a los
            procesos de matrícula.</span
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <v-container>
        <v-row>
          <v-col md="6" lg="4">
            <v-select
              label="¿Qué año desea consultar?"
              filled
              rounded
              :loading="areYearsLoading"
              :disabled="areYearsLoading"
              :items="availableYears"
              item-text="year"
              item-value="year"
              v-model="selectedYear"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.year }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-card class="pa-5 rounded-lg elevation-0 mb-5" outlined>
        <IconCardWithActionButton
          :loading="false"
          buttonLegend="Descargar reporte"
          colorTheme="green"
          icon="file-excel"
          subTitle="Registro de cuantos estudiantes de antiguo ingreso han cancelado y se han matrículado."
          title="Progreso de matrícula de estudiantes de antíguo ingreso"
          @action="openSummaryOfEnrolledStudentReport()"
        ></IconCardWithActionButton>
      </v-card>
    </div>
  </div>
</template>

<script>
import IconCardWithActionButton from "@/components/elements/dashboard/IconCardWithAction.vue";
import settingsRepository from "@/repositories/settingsRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "EnrollmentReports",
  components: { IconCardWithActionButton },
  title: "Reporte de proceso de matrícula",
  data() {
    return {
      availableYears: [],
      areYearsLoading: false,
      selectedYear: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Reportes del proceso de matrícula",
        route: "enrollment_reports",
      },
    ]);
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Reportes de proceso de matrícula"
    );
    this.loadYears();
  },
  methods: {
    openSummaryOfEnrolledStudentReport() {
      if (this.selectedYear === null) {
        this.fireToast({
          icon: "warning",
          title: "Debes seleccionar el año que deseas consultar",
        });
        return;
      }
      window.open(this.summaryOfEnrolledStudentsURL);
    },
    loadYears() {
      this.areYearsLoading = true;
      settingsRepository
        .getAllYears()
        .then(({ data }) => {
          this.availableYears = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areYearsLoading = false;
        });
    },
  },
  computed: {
    summaryOfEnrolledStudentsURL() {
      let baseURL = "";
      const ENV = process.env.REPORTS_APP_ENV || "local";
      if (ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }

      return `${baseURL}/enrollment-process/payments/excel/${this.selectedYear}`;
    },
  },
};
</script>