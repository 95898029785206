import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllYears = () => {
  return ApiService.get("schemas");
};

// -----------------User settings--------------------

export const getUserSettings = (userId) => {
  return ApiService.get(`user-settings/${userId}`);
};

export const saveUserSettings = (userId, payload) => {
  return ApiService.post(`user-settings/${userId}`, payload);
};

// --------------------------------------------------

export default {
  getAllYears,
  getUserSettings,
  saveUserSettings,
};
